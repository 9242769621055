import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { GatsbyImage } from "gatsby-plugin-image";
import { SliceZone, PrismicRichText } from "@prismicio/react";
// import SliceZone from "@prismicio/react";
import components from "../components/slices";
import Seo from "../components/meta/Seo";
import Layout from "../components/common/Layout";
import { isBrowser } from "../utilities/checkEnvironment";


const Lectures = ({ data }) => {
  if (!data) return null;
  const { page, lectures } = data;
  const [customMargin, setCustomMargin] = useState(80);

  useEffect(() => {
    if (isBrowser) {
      const pageTitleElem = document.querySelector(".page-title");
      if (pageTitleElem) {
        setCustomMargin(pageTitleElem.getClientRects()[0].x + 80);
        // window.addEventListener("resize", () =>
        //   setCustomMargin(pageTitleElem.getClientRects()[0].x + 80)
        // );
        // return () =>
        //   window.removeEventListener("resize", () =>
        //     setCustomMargin(pageTitleElem.getClientRects()[0].x + 80)
        //   );
      }
    }
    return null;
  }, []);

  return (
    <>
      <Seo page={page} />
      <Layout>
        {/* <Container>
          <h1 className="page-title pt-5 mb-0">Lectures</h1>
        </Container> */}
        <div
          style={{ marginLeft: customMargin }}
          className="about-image-wraper"
        >
          <p className="text-light">Photographer: {lectures.data.photographer_name}</p>
          <GatsbyImage
            image={lectures.data.image_mobile.gatsbyImageData}
            alt={lectures.data.image_mobile.alt || "Kokkinou - Kourkoulas"}
            className="about-image d-sm-none"
          />
          <GatsbyImage
            image={lectures.data.image.gatsbyImageData}
            alt={lectures.data.image.alt || "Kokkinou - Kourkoulas"}
            className="about-image d-none d-sm-block"
          />
        </div>
        <div
          className="tab-wraper ms-0 pe-2 pe-xl-5"
          style={{ paddingLeft: customMargin }}
        >
          <Tab.Container defaultActiveKey="lectures">
            <Row className="my-5">
              <Col xl={4} className="tab-nav mb-5 ">
                <Nav variant="pills" className="flex-column">
                  <Row>
                    <Col md={6} xl={12}>
                      <Nav.Item className="mb-4">
                        <Nav.Link eventKey="lectures">
                          <h3>
                            <span className="link-underline-hover">
                              Lectures - Interviews
                            </span>
                          </h3>
                        </Nav.Link>
                      </Nav.Item>
                    </Col>
                  </Row>
                </Nav>
              </Col>
              <Col xl={8} className="tab-content">
                <Tab.Content className="ps-lg-3">
                  <Tab.Pane eventKey="lectures" className="about-lectures">
                    <Row>
                      <SliceZone
                        slices={lectures.data.body}
                        components={components}
                      />
                    </Row>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </Layout>
    </>
  );
};

export const query = graphql`
  query allLecturesQuery($lang: String) {
    settings: prismicSettings(lang: { eq: $lang }) {
      ...prismicSettingsFragment
    }
    page: prismicLectures(lang: { eq: $lang }) {
      data {
        meta_title
        meta_description
        meta_image {
          alt
          dimensions {
            height
            width
          }
          url
        }
        title {
          text
        }
      }
    }
    lectures: prismicLectures {
      data {
        title {
          text
        }
        image {
          gatsbyImageData(placeholder: BLURRED)
          alt
        }
        image_mobile {
          gatsbyImageData(placeholder: BLURRED)
          alt
        }
        photographer_name
        body {
          ... on PrismicLecturesDataBodyEnglishLectures {
            id
            slice_type
            items {
              lecture_title {
                text
              }
              lecture_description {
                richText
              }
              lecture_video {
                embed_url
                html
                title
              }
            }
          }
          ... on PrismicLecturesDataBodyGreekLectures {
            id
            slice_type
            items {
              lecture_title {
                text
              }
              lecture_description {
                richText
              }
              lecture_video {
                embed_url
                html
                title
              }
            }
          }
        }
        title {
          text
        }
      }
  }
  }
`;

export default Lectures;
